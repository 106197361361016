import React, { useState, useEffect } from "react";
import "../../styles/Bay.css";
import {BayStatus, BayPIN, BayDbModel, getBayMinutesLeft} from "../../models/bays";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import ButtonBase from "@material-ui/core/ButtonBase";
import { Facility } from "../../models/facility";
import {BaySettings} from "./BaySettings";

interface BayProps {
  bay: BayDbModel;
}

export const Bay: React.FC<BayProps> = ({ bay }) => {
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const dispatch = useDispatch();

  const selectedFacility = useSelector<RootState, Facility>(
      (selector) => selector.facility.selectedFacility
  );

  const calculateTimeLeft = () => {
    // Only calculate the time when in_use
    if (bay.bay_state_id === BayStatus.AVAILABLE) {
      return 0;
    }

    if (bay.pin !== undefined && bay.pin !== null) {
      if (bay.start_time === null) {
        return 0;
      }

      if (selectedFacility !== null && selectedFacility.pinBehaviorStr !== null) {
        let minutesLeft = getBayMinutesLeft(selectedFacility.pinBehaviorStr, selectedFacility.pinLeadTimeMinutes, bay);

        if (minutesLeft < 0) {
          return 0
        }

        const percentLeft = 100 - (minutesLeft / bay.minutes) * 100;
        // percentLeft = percentLeft < 2 ? 2 : percentLeft;
        // if (bay.name === "1001") console.log(`${percentLeft}%`);
        return +percentLeft.toPrecision(2);

      } else {
        var start = new Date(bay.start_time);
        var now = new Date(Date.now());
        var end = new Date(start.getTime() + 1000 * 60 * bay.minutes);

        if (end < now) return 98;
        var timeLeft2 = moment.utc(
            moment(end, "DD/MM/YYYY HH:mm:ss").diff(
                moment(now, "DD/MM/YYYY HH:mm:ss")
            )
        );
        var minutesLeft = moment
            .duration(timeLeft2.format("HH:mm:ss"))
            .asMinutes();
        const percentLeft = 100 - (minutesLeft / bay.minutes) * 100;
        // percentLeft = percentLeft < 2 ? 2 : percentLeft;
        // if (bay.name === "1001") console.log(`${percentLeft}%`);
        return +percentLeft.toPrecision(2);

      }
    }
    return 0;
  }

  const calculateTimeLeftOld = () => {
    // Only calculate the time when in_use
    if (bay.bay_state_id === BayStatus.AVAILABLE) {
      return 0;
    }

    if (bay.pin !== undefined && bay.pin !== null && bay.pin !== "") {
      if (bay.start_time === null) {
        return 0;
      }
      var start = new Date(bay.start_time);
      var now = new Date(Date.now());
      var end = new Date(start.getTime() + 1000 * 60 * bay.minutes);

      if (end < now) return 98;
      var timeLeft2 = moment.utc(
        moment(end, "DD/MM/YYYY HH:mm:ss").diff(
          moment(now, "DD/MM/YYYY HH:mm:ss")
        )
      );
      var minutesLeft = moment
        .duration(timeLeft2.format("HH:mm:ss"))
        .asMinutes();
      var percentLeft = 100 - (minutesLeft / bay.minutes) * 100;
      // percentLeft = percentLeft < 2 ? 2 : percentLeft;
      // if (bay.name === "1001") console.log(`${percentLeft}%`);
      return +percentLeft.toPrecision(2);
    }
    return 0;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const handleClose = () => setShowSettings(false);
  const handleShow = () => {
    //force an update before showing settings screen
    //dispatch(getBay(selectedFacility.uuid, bay.uuid));
    setShowSettings(true);
  };

  const getBayColorsBayStateId = (stateId: number) => {
    switch (stateId) {
      case BayStatus.AVAILABLE:
        return ["#97DE3D", "#2EAB3A"];
      case BayStatus.INUSE:
        return ["#FB963B", "#F77D0F"];
      case BayStatus.NEWASSIGNMENT:
      case BayStatus.BUSSING:
      case BayStatus.WAITER:
        return ["#8C48AB","#8C48AB"]
      case BayStatus.RESERVED:
        return ["#4D2178", "#4D2178"];
      case BayStatus.SERVICE:
      case BayStatus.TECHNICAL:
        return ["#EF4327","#EF4327"];
      case BayStatus.NONASSIGNABLE:
        return ["#C1C1C1", "#C1C1C1"];
      case BayStatus.INUSE_ANDROID:
      case BayStatus.INUSE_IOS:
        return ["#FD009B", "#350028"];
      case BayStatus.INUSE_SP_IN_BAY:
        return ["#1B4258", "#041A27"];
      case BayStatus.INUSE_MP_IN_BAY:
        return ["#56EBFC", "#008B9B"];
    }
    return ["#C1C1C1", "#C1C1C1"];
  };

  useEffect(() => {
    const timer = setTimeout(() => setTimeLeft(calculateTimeLeft()), 1000);
    return () => clearTimeout(timer);
  }, [timeLeft]);

  useEffect(() => {
    if (selectedFacility !== null) {
      setTimeLeft(calculateTimeLeft());
    }
  }, [dispatch, bay.uuid]);

  const colors: string[] = getBayColorsBayStateId(bay.bay_state_id);
  const color1 = colors[0];
  const color2 = colors[1];

  return (
    <React.Fragment>
      {
        <ButtonBase
          className={`bay ${
            timeLeft >= 98 && bay.bay_state_id !== BayStatus.AVAILABLE ? "bayOutOfTime" : ""
          }`}
          style={{
            background: timeLeft > 0
                ? `linear-gradient(to bottom, ${color1} ${timeLeft}%, ${color2} ${timeLeft}%)`
                : `linear-gradient(to bottom, ${color1}, ${color2})`,
            borderRadius: "10px",
          }}
          onClick={handleShow}
        >
          <label className="bayName">{bay.name}</label>
          {/* {timeLeft >= 98 && (
            <React.Fragment>
              <label className="bayStatusLabelExpired">EXPIRED</label>
            </React.Fragment>
          )} */}
          {/* {timeLeft < 98 && (
            <label className="bayStatusLabel">
              {BayStatus[bay.bay_state_id]}
            </label>
          )} */}
        </ButtonBase>
      }
      <BaySettings bay={bay} show={showSettings} handleClose={handleClose} />
    </React.Fragment>
  );
};

export default Bay;
