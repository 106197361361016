import React, { useState, useEffect } from "react";
import "../../styles/Bay.css";
import { BaySettings } from "./BaySettings";
import { BayStatus, BayDbModel } from "../../models/bays";
import ButtonBase from "@material-ui/core/ButtonBase";

interface BayProps {
  bay: BayDbModel;
}

export const BayLayoutItem: React.FC<BayProps> = ({ bay }) => {
  const [showSettings, setShowSettings] = useState<boolean>(false);

  const handleClose = () => setShowSettings(false);
  const handleShow = () => setShowSettings(true);

  const getBayColorBayStateId = (stateId: number) => {
    switch (stateId) {
      case BayStatus.AVAILABLE:
        return "#97DE3D";
      case BayStatus.INUSE:
        return "#FB963B";
      case BayStatus.NEWASSIGNMENT:
      case BayStatus.BUSSING:
      case BayStatus.WAITER:
        return "#8C48AB"
      case BayStatus.RESERVED:
        return "#4D2178";
      case BayStatus.SERVICE:
      case BayStatus.TECHNICAL:
        return "#EF4327";
      case BayStatus.NONASSIGNABLE:
        return "#C1C1C1";
      case BayStatus.INUSE_ANDROID:
      case BayStatus.INUSE_IOS:
        return "#FD009B";
      case BayStatus.INUSE_SP_IN_BAY:
        return "#1B4258";
      case BayStatus.INUSE_MP_IN_BAY:
        return "#56EBFC";
    }
    return "#C1C1C1";
  };

  return (
    <React.Fragment>
      {
        <ButtonBase
          className="bayLayoutItem"
          style={{
            background: `${getBayColorBayStateId(bay.bay_state_id)}`,
            borderRadius: "5px",
          }}
          onClick={handleShow}
        >
          <label className="bayLayoutItemName">{bay.name}</label>
        </ButtonBase>
      }
      <BaySettings bay={bay} show={showSettings} handleClose={handleClose} />
    </React.Fragment>
  );
};

export default BayLayoutItem;
