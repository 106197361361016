import React, { useEffect, useState } from "react";
import {
    AppBar,
    Button,
    CircularProgress,
    createStyles,
    Dialog,
    DialogContent,
    DialogContentText,
    Divider,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    makeStyles,
    MenuItem, MenuList,
    MuiThemeProvider,
    Select,
    Snackbar,
    TextField,
    Theme,
    Toolbar,
    Typography,
    useTheme,
} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {BayDbModel, BayPIN, BayStatus, getBayMinutesLeft, Pin} from "../../models/bays";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import { changeBayState } from "../../redux/actions/bays.actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
    addBayPinTime,
    extendBayPinTime,
    completeBayPin,
    createBayPin,
    pinErrorHandled,
    startBayPinTime, getPinById, upcomingBayPin, pinUpcomingHandled
} from '../../redux/actions/pins.actions';
import { Facility } from "../../models/facility";

interface BaySettingsProps {
    bay: BayDbModel;
    show: boolean;
    handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(1),
            flex: 1,
            textAlign: "left",
            font: "normal normal normal 36px/44px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: 1,
        },
        root: {
            background: "white",
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        notime: {
            color: "red",
        },
        time: {
            color: "primary",
        },
        dialog: {
            borderRadius: "50x",
        },
        rootStyle: {
            borderRadius: 25,
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        formControl: {
            margin: theme.spacing(2),
            minWidth: 120,
        }, //
        // selectEmpty: {
        //   marginTop: theme.spacing(2)
        // }
    })
);

export const BaySettings: React.FC<BaySettingsProps> = ({
                                                            bay,
                                                            show,
                                                            handleClose,
                                                        }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [timeLeft, setTimeLeft] = useState("00:00:00");
    const [minutes, setMinutes] = React.useState("60");
    const [status, setStatus] = React.useState(bay.bay_state_id);
    const [mode, setMode] = React.useState("SP");
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    const [inputPin, setInputPin] = React.useState("");

    // const pins = useSelector<RootState, BayPIN[]>((selector) =>
    //   selector.pins.bayPins.find((x) => x.bayId === bay.uuid)
    // );

    const selectedFacility = useSelector<RootState, Facility>(
        (selector) => selector.facility.selectedFacility
    );

    const loading = useSelector<RootState, boolean>(
        (selector) => selector.pins.isLoading
    );

    const error = useSelector<RootState, Error | undefined>(
        (selector) => selector.pins.errorObject
    );

    const upcomingPin = useSelector<RootState, Pin | undefined>(
        (selector) => selector.pins.upcoming
    );

    const handleBaySettingsClose = () => {
        dispatch(pinErrorHandled());
        dispatch(pinUpcomingHandled());
        handleClose();
    };

    const theme = useTheme();
    const handleSnackBarClose = (
        event?: React.SyntheticEvent,
        reason?: string
    ) => {
        dispatch(pinErrorHandled());

        if (reason === "clickaway") {
            return;
        }

        setSnackBarOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setMinutes(event.target.value as string);
    };

    const handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setStatus(event.target.value as number);
    };

    const handleModeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setMode(event.target.value as string);
    };

    const handleInputPinChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setInputPin(event.target.value as string);
    };

    const changeStatus = () => {
        dispatch(changeBayState(bay, status, selectedFacility.uuid));
        // setSnackBarMessage("Bay Status Updated!");
        // setSnackBarOpen(true);
    };

    const changeStatusExplicit = (statusToChangeTo: BayStatus) => {
        dispatch(changeBayState(bay, statusToChangeTo, selectedFacility.uuid));
        setStatus(statusToChangeTo);
    };

    const createPin = (bay: string, minutes: string, mode: string) => {
        dispatch(createBayPin(+minutes, bay, mode, "", selectedFacility.uuid));
        changeStatusExplicit(BayStatus.INUSE);
        // setSnackBarMessage("Bay PIN Created!");
        // setSnackBarOpen(true);
    };

    const startPinTime = (bay: string, pin: string, mode: string) => {
        if (pin != undefined && pin != "") {
            dispatch(startBayPinTime(pin, bay, selectedFacility.uuid, mode));
            // BE drives this state
            // changeStatusExplicit(BayStatus.INUSE);
        }
        // setSnackBarMessage("Bay PIN Success!");
        // setSnackBarOpen(true);
    };

    const getUpcomingPin = (bayId: string) => {
        if (bayId != undefined && bayId != "") {
            dispatch(upcomingBayPin(selectedFacility.uuid, bayId));
        }
    };

    const extendPinTime = (bay: string, pin: string, extendPin: string, mode: string) => {
        if (extendPin !== undefined && extendPin !== "" && pin !== undefined && pin !== "") {
            dispatch(extendBayPinTime(pin, extendPin, bay, selectedFacility.uuid, mode));
            // changeStatusExplicit(BayStatus.INUSE);
        }
        // setSnackBarMessage("Bay PIN Success!");
        // setSnackBarOpen(true);
    };

    const completePin = (bay: string, pin: string) => {
        if (pin !== undefined && pin !== "") {
            dispatch(completeBayPin(pin, bay, selectedFacility.uuid));
            // BE drives this state
            // changeStatusExplicit(BayStatus.AVAILABLE);
        }
        // setSnackBarMessage("Bay PIN Completed!");
        // setSnackBarOpen(true);
    };

    const isBayInUse = (stateId: number) => {
        switch (stateId) {
            case BayStatus.RESERVED:
            case BayStatus.INUSE:
            case BayStatus.WAITER:
            case BayStatus.NEWASSIGNMENT:
            case BayStatus.OFFLINE:
            case BayStatus.INUSE_ANDROID:
            case BayStatus.INUSE_IOS:
            case BayStatus.INUSE_SP_IN_BAY:
            case BayStatus.INUSE_MP_IN_BAY:
                return true;
            default:
                return false;
        }
    };

    const getBayStatusNameBayStateId = (stateId: number) => {
        if (BayStatus[stateId] === undefined) {
            return "Non Assignable";
        }

        switch (stateId) {
            case BayStatus.INUSE_ANDROID:
            case BayStatus.INUSE_IOS:
                return "Inrange App In Use";
            case BayStatus.INUSE_SP_IN_BAY:
                return "Inrange In Use";
            case BayStatus.INUSE_MP_IN_BAY:
                return "Inrange+ In Use";
        }

        return BayStatus[stateId].toString();
    };

    const getBayColorBayStateId = (stateId: number) => {
        switch (stateId) {
            case BayStatus.AVAILABLE:
                return "#97DE3D";
            case BayStatus.INUSE:
                return "#FB963B";
            case BayStatus.NEWASSIGNMENT:
            case BayStatus.BUSSING:
            case BayStatus.WAITER:
                return "#8C48AB"
            case BayStatus.RESERVED:
                return "#4D2178";
            case BayStatus.SERVICE:
            case BayStatus.TECHNICAL:
                return "#EF4327";
            case BayStatus.NONASSIGNABLE:
                return "#C1C1C1";
            case BayStatus.INUSE_ANDROID:
            case BayStatus.INUSE_IOS:
                return "#FD009B";
            case BayStatus.INUSE_SP_IN_BAY:
                return "#1B4258";
            case BayStatus.INUSE_MP_IN_BAY:
                return "#56EBFC";
        }
        return "#C1C1C1";
    };

    const calculateTimeLeft = () => {
        if (bay.pin !== undefined && bay.pin !== null) {
            if (bay.start_time === null) {
                var hours = Math.floor(bay.minutes / 60);
                var minutes = bay.minutes % 60;
                return `${hours
                    .toString()
                    .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:00`;
            }

            if (selectedFacility !== null && selectedFacility.pinBehaviorStr !== null) {
                let minutes = getBayMinutesLeft(selectedFacility.pinBehaviorStr, selectedFacility.pinLeadTimeMinutes, bay);

                if (minutes < 0) {
                    return "00:00:00"; // Handle negative values if needed
                }

                const hours = Math.floor(minutes / 60);
                const remainingMinutes = Math.floor(minutes % 60);
                const seconds = Math.round((minutes % 1) * 60); // Calculate the seconds

                const pad = (num: number) => (num < 10 ? `0${num}` : num);

                const hh = pad(hours);
                const mm = pad(remainingMinutes);
                const ss = pad(seconds);

                return `${hh}:${mm}:${ss}`;

            } else {
                var start = new Date(bay.start_time);
                var now = new Date(Date.now());
                var end = new Date(start.getTime() + 1000 * 60 * bay.minutes);

                if (end < now) return "00:00:00";
                var timeLeft = moment.utc(
                    moment(end, "DD/MM/YYYY HH:mm:ss").diff(
                        moment(now, "DD/MM/YYYY HH:mm:ss")
                    )
                );

                return timeLeft.format("HH:mm:ss");
            }

        }

        return "00:00:00";
    };

    useEffect(() => {
        // const timeout = setTimeout(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        // return clearTimeout(timeout);
    });

    useEffect(() => {
        setTimeLeft(calculateTimeLeft());
        if (bay !== undefined) {
            if (bay.pin !== undefined && bay.pin !== null) setInputPin(bay.pin);
        }
    }, [bay.uuid]);

    useEffect(() => {
        // dispatch(getBay(selectedFacility.uuid, bay.uuid));
        // console.log("Calling");
    }, []);

    return (
        <MuiThemeProvider theme={theme}>
            <Dialog
                // fullScreen
                classes={{
                    paper: classes.rootStyle,
                }}
                className={classes.dialog}
                open={show}
                onClose={handleBaySettingsClose}
                aria-labelledby="form-dialog-title"
            >
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Floor {bay.floor} - Bay {bay.name}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleBaySettingsClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
                {/* <DialogTitle id="form-dialog-title">Bay: {bay.name}</DialogTitle> */}
                <DialogContent>
                    <Typography
                        style={{
                            display: 'inline-block',
                            font: 'normal normal medium 21px/25px Montserrat'
                        }}>
                        Status: &nbsp;
                    </Typography>
                    <Typography
                        style={{
                            display: 'inline-block',
                            font: 'normal normal bold 21px/25px Montserrat',
                            color: getBayColorBayStateId(bay.bay_state_id),
                        }}>
                        {getBayStatusNameBayStateId(bay.bay_state_id)}
                    </Typography>
                    <React.Fragment>
                        {loading && (
                            <div className="loading">
                                {" "}
                                <Typography color="primary">
                                    Loading Bay Settings...
                                </Typography>{" "}
                                <CircularProgress color="primary"/>
                            </div>
                        )}

                        <div className="TextField-with-border-radius">
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="change-status-label">State</InputLabel>
                                <Select
                                    labelId="change-status-label"
                                    id="status-select"
                                    value={status}
                                    onChange={handleStatusChange}
                                    label="Status"
                                >
                                    <MenuItem value={1}>Available</MenuItem>
                                    {
                                        /* <MenuItem value={2}>Reserved</MenuItem> */
                                        <MenuItem value={3}>In Use</MenuItem>
                                        /* <MenuItem value={4}>Service</MenuItem>
                                      <MenuItem value={5}>Bussing</MenuItem> */
                                    }
                                    <MenuItem value={6}>Technical</MenuItem>
                                    <MenuItem value={7}>Non Assignable</MenuItem>
                                    {/* <MenuItem value={8}>Waiter</MenuItem>
                  <MenuItem value={9}>New Assignment</MenuItem>
                  <MenuItem value={10}>Offline</MenuItem> */}
                                </Select>
                            </FormControl>
                            {bay.bay_state_id !== status && (
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={changeStatus}
                                    >
                                        Change Status
                                    </Button>
                                </FormControl>
                            )}
                        </div>

                        {!loading && bay.pin !== undefined && bay.pin !== null && isBayInUse(bay.bay_state_id) && (
                            <React.Fragment>
                                <Divider/>
                                <div className="TextField-with-border-radius">
                                    <Typography variant="h4">Current Pin Info</Typography>
                                    <Grid container justify="space-between">
                                        <FormControl
                                            variant="outlined"
                                            className={classes.formControl}
                                        >
                                            <Typography variant="h4">{bay.pin}</Typography>
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                            className={classes.formControl}
                                        >
                                            <TextField
                                                label="Time Remaining"
                                                variant="outlined"
                                                color="primary"
                                                value={timeLeft}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <ScheduleIcon/>
                                                        </InputAdornment>
                                                    ),
                                                    className:
                                                        timeLeft === "00:00:00"
                                                            ? classes.notime
                                                            : classes.time,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                    <Snackbar
                        open={snackBarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackBarClose}
                    >
                        <Alert onClose={handleSnackBarClose} severity="success">
                            {snackBarMessage}
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={error !== undefined}
                        autoHideDuration={6000}
                        onClose={handleSnackBarClose}
                    >
                        <Alert onClose={handleSnackBarClose} severity="error">
                            {error?.message}
                        </Alert>
                    </Snackbar>
                </DialogContent>
                {(!process.env.NODE_ENV ||
                    process.env.NODE_ENV === "development" ||
                    window.location.href.includes("dev-") ||
                    window.location.href.includes("test-")) && (
                    <div>
                        <div>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Divider/>
                                    <Typography className={classes.heading}>
                                        TESTING TOOLS
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            {upcomingPin && (
                                                <>
                                                    <Typography variant="h6" className={classes.heading}>
                                                        PIN | Min | Mode | IsGroup | Welcome | PlayerName | TimeSlot start | TimeSlot end
                                                    </Typography>
                                                    <Typography className={classes.heading}>
                                                        {`${upcomingPin.pin} | ${upcomingPin.minutes} | ${upcomingPin.playMode} | ${upcomingPin.isGroupPin} | ${upcomingPin.welcomeMessage === null ? '-' : upcomingPin.welcomeMessage} | ${upcomingPin.playerId === null ? '-' : upcomingPin.playerName} | ${new Date(upcomingPin.timeSlotStart).toLocaleString()} - ${new Date(upcomingPin.timeSlotEnd).toLocaleString()}`}
                                                    </Typography>
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                className={classes.formControl}
                                            >
                                                <TextField
                                                    label="PIN"
                                                    variant="outlined"
                                                    color="primary"
                                                    value={inputPin}
                                                    onChange={handleInputPinChange}
                                                />
                                            </FormControl>
                                            <FormControl
                                                variant="outlined"
                                                className={classes.formControl}
                                            >
                                                <Select
                                                    id="mode-select"
                                                    value={mode}
                                                    onChange={handleModeChange}
                                                    IconComponent={() => <ChevronRightIcon/>}
                                                >
                                                    <MenuItem value={"SP"}>Inrange</MenuItem>
                                                    <MenuItem value={"MP"}>Inrange+</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} container spacing={2}>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => getUpcomingPin(bay.uuid)}
                                                >
                                                    Upcoming PIN
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => startPinTime(bay.uuid, inputPin, mode)}
                                                >
                                                    Start PIN
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => completePin(bay.uuid, bay.pin)}
                                                >
                                                    Complete PIN
                                                </Button>
                                            </Grid>
                                            {bay.pin !== undefined && bay.pin !== null && isBayInUse(bay.bay_state_id) && (
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => extendPinTime(bay.uuid, bay.pin, inputPin, mode)}
                                                    >
                                                        Extend PIN
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                )}
            </Dialog>
        </MuiThemeProvider>
    );
};
