import {
    PINLOADING,
    PINLOADED,
    PINCREATING,
    PINCREATED,
    PINUPDATING,
    PINUPDATED,
    PINCOMPLETED,
    PINLOADINGDONE,
    PINERROR, PINERRORHANDLED, PIN_UPCOMING, PIN_UPCOMING_HANDLED,
} from './pins.actions.types';
import axios, { AxiosError } from 'axios';
import { BayPIN } from "../../models/bays";
import { assignBayPin, getBay } from "./bays.actions";

export const getBayPin = (bay: string, facility: string) => (
  dispatch: Function
) => {
  dispatch({ type: PINLOADING });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/${facility}/Pins/${bay}`)
    .then((res) => {
      if (res.data.length > 0) {
        //this could be a list
        // console.log(res.data);
        const bayPin: BayPIN[] = res.data;
        if (bayPin !== null && bayPin !== undefined && bayPin.length > 0) {
          dispatch({ type: PINLOADED, payload: bayPin });
        } else
          dispatch({
            type: PINLOADINGDONE,
          });
      } else
        dispatch({
          type: PINLOADINGDONE,
        });
    })
    .catch((_err) => {
      dispatch({
        type: PINLOADINGDONE,
      });
    });
};

export const createBayPin = (
  minutes: number,
  bay: string,
  mode: string,
  pin: string,
  facility: string
) => (dispatch: Function) => {
  dispatch({ type: PINCREATING });

  axios
    .post(`${process.env.REACT_APP_BASE_URL}/${facility}/Pins`, {
      minutes: minutes,
      bayId: bay,
      playMode: mode,
      PIN: pin,
    })
    .then((res) => {
      dispatch({ type: PINCREATED, payload: res.data });
      dispatch(assignBayPin(res.data.pin, res.data.minutes, bay, facility));
    })
    .catch((error: AxiosError) => {
      dispatch({ type: PINERROR, payload: (error.response !== undefined) ? error.response.data?.message : error.message });
    });
};

export const createFreePin = (minutes: string, facility: string) => (
  dispatch: Function
) => {
  dispatch({ type: PINCREATING });
  axios
    .post(`${process.env.REACT_APP_BASE_URL}/${facility}/Pins`, {
      minutes: minutes,
    })
    .then((res) => {
      dispatch({ type: PINCREATED, payload: res.data });
    })
    .catch((error: AxiosError) => {
      dispatch({ type: PINERROR, payload: (error.response !== undefined) ? error.response.data?.message : error.message });
    });
};

export const getPinById = (
    pinId: string
) => (dispatch: Function) => {
    dispatch({ type: PINUPDATING });
    axios
        .get(
            `${process.env.REACT_APP_BASE_URL}/Pins/${pinId}`,
        )
        .then((res) => {
            console.log(res);
        })
        .catch((error: AxiosError) => {
            dispatch({ type: PINERROR, payload: (error.response !== undefined) ? error.response.data?.message : error.message });
        });
};

export const startBayPinTime = (
  pin: string,
  bay: string,
  facility: string,
  mode: string
) => (dispatch: Function) => {
  dispatch({ type: PINUPDATING });
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/${facility}/Pins/StartPinTime/${pin}`,
      {
        bayId: bay,
        playMode: mode,
      }
    )
    .then((res) => {
      dispatch({ type: PINUPDATED, payload: res.data });
      dispatch(getBay(facility, bay));
    })
    .catch((error: AxiosError) => {
      dispatch({ type: PINERROR, payload: (error.response !== undefined) ? error.response.data?.message : error.message });
    });
};

export const extendBayPinTime = (
    pin: string,
    extendPin: string,
    bay: string,
    facility: string,
    mode: string
) => (dispatch: Function) => {
  dispatch({ type: PINUPDATING });
  axios
      .post(
          `${process.env.REACT_APP_BASE_URL}/${facility}/Pins/ExtendPinTime/${pin}`,
          {
            bayId: bay,
            playMode: mode,
            extendPin: extendPin
          }
      )
      .then((res) => {
        dispatch({ type: PINUPDATED, payload: res.data });
        dispatch(getBay(facility, bay));
      })
      .catch((error: AxiosError) => {
        dispatch({ type: PINERROR, payload: (error.response !== undefined) ? error.response.data?.message : error.message });
      });
};

export const upcomingBayPin = (
    facilityId: string,
    bayId: string
) => (dispatch: Function) => {
    axios
        .get(
            `${process.env.REACT_APP_BASE_URL}/${facilityId}/Pins/${bayId}/upcoming`,
        )
        .then((res) => {
            dispatch({ type: PIN_UPCOMING, payload: res.data });
        })
        .catch((error: AxiosError) => {
            dispatch({ type: PINERROR, payload: (error.response !== undefined) ? error.response.data?.message : error.message });
        });
};

export const addBayPinTime = (
  pin: string,
  bay: string,
  facility: string,
  mode: string
) => (dispatch: Function) => {
  dispatch({ type: PINUPDATING });
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/${facility}/Pins/AddPinTime/${pin}`,
      {
        bayId: bay,
        playMode: mode,
      }
    )
    .then((res) => {
      dispatch({ type: PINUPDATED, payload: res.data });
      dispatch(getBay(facility, bay));
    })
    .catch((error: AxiosError) => {
      dispatch({ type: PINERROR, payload: (error.response !== undefined) ? error.response.data?.message : error.message });
    });
};

export const completeBayPin = (pin: string, bay: string, facility: string) => (
  dispatch: Function
) => {
  dispatch({ type: PINUPDATING });
  axios
    .put(`${process.env.REACT_APP_BASE_URL}/${facility}/Pins/${pin}/complete`, {
      bayId: bay,
    })
    .then((res) => {
      dispatch({ type: PINCOMPLETED, payload: res.data });
      dispatch(getBay(facility, bay));
    })
    .catch((error: AxiosError) => {
      dispatch({ type: PINERROR, payload: (error.response !== undefined) ? error.response.data?.message : error.message });
    });
};

export const pinErrorHandled = () => (
    dispatch: Function
) => {
  dispatch({ type: PINERRORHANDLED });
};

export const pinUpcomingHandled = () => (
    dispatch: Function
) => {
    dispatch({ type: PIN_UPCOMING_HANDLED });
};