import React, { useEffect, useState } from 'react';
import '../styles/Bays.css';
import {
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  makeStyles,
  MuiThemeProvider,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { CreatePinOptions } from '../components/pins/CreatePinOptions';
import { AvailablePins } from '../components/pins/AvailablePins';
import { AvailableStaticPins } from '../components/pins/AvailableStaticPins';
import AvailableIcon from '../assets/status_available.svg';
import NotAvailableIcon from '../assets/status_not_available.svg';
import InrangeInUseIcon from '../assets/status_inrange_in_use.svg';
import InrangePlusInUseIcon from '../assets/status_inrange_plus_in_use.svg';
import AppInUseIcon from '../assets/status_app_in_use.svg';
import FacilityInfo from '../components/FacilityInfo';
import BaysStatusView from '../components/bays/BaysStatusView';
import BayLayoutView from '../components/bays/BayLayoutView';
import { FindPin } from '../components/pins/FindPins';
import { FindPinOptions } from '../components/pins/FindPinOptions';
import {selectFacility} from "../redux/actions/facility.actions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    loading: {
      display: "flex",
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      textAlign: "center",
    },
    floor: {
      background: "black",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      background: "white",
      borderRadius: "25px",
    },
  })
);

export const Bays: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showAvailablePins, setShowAvailablePins] = useState<boolean>(false);
  const [showAvailableStaticPins, setShowAvailableStaticPins] = useState<
    boolean
  >(false);

  const [showFindPins, setShowFindPins] = useState<boolean>(false);

  const [showOverview, setShowOverview] = React.useState(true);
  const [showLayout, setShowLayout] = React.useState(false);

  const [showCreatePin, setShowCreatePin] = useState<boolean>(false);

  const loading = useSelector<RootState, boolean>(
    (selector) => selector.bays.isLoading
  );

  const handleAvailablePinsClose = () => setShowAvailablePins(false);
  const handleAvailablePinsShow = () => setShowAvailablePins(true);
  const handleAvailableStaticPinsClose = () => setShowAvailableStaticPins(false);
  const handleAvailableStaticPinsShow = () => setShowAvailableStaticPins(true);

  const handleCreatePinClose = () => setShowCreatePin(false);
  const handleCreatePinShow = () => setShowCreatePin(true);
  
  const handleFindPinsClose= () => setShowFindPins(false);
  const handleFindPinsShow = () => setShowFindPins(true);

  const toggleOverview = () => {
    setShowOverview(true);
    setShowLayout(false);
  };

  const toggleLayout = () => {
    setShowOverview(false);
    setShowLayout(true);
  };

  const role = useSelector<RootState, string>(
    (selector) => selector.facility.role
  );

    const allowGroupPinOption = useSelector<RootState, boolean>(
    (selector) => (selector.facility.selectedFacility.pinGroupOptionStr.toLowerCase()  === 'allow')
    );

  return (
    <MuiThemeProvider theme={theme}>
      <FacilityInfo />

      <Grid container spacing={2}>
          {!loading &&
            <Grid item>
              <FormControl variant="outlined" className={classes.formControl}>
                <Button variant="outlined"
                        size="small"
                        onClick={handleAvailablePinsShow}>
                    One-Time Bookings
                </Button>
              </FormControl>
            </Grid>
          }
        <Grid item style={{ flex: 1 }}>
          {!loading && (role === "MANAGER" || role === "ADMIN") && (
              <FormControl variant="outlined" className={classes.formControl}>
                <Button variant="outlined"
                        size="small"
                        onClick={handleAvailableStaticPinsShow}>
                  Static PINs
                </Button>
              </FormControl>
          )}
        </Grid>
        <Grid item style={{ flex: 10, justifyContent: 'flex-start'}}>
          {!loading && (role === "MANAGER" || role === "ADMIN") && (
              <FormControl variant="outlined" className={classes.formControl}>
                <Button variant="outlined"
                        size="small"
                        onClick={handleFindPinsShow}>
                  Find Booking
                </Button>
              </FormControl>
          )}
        </Grid>
        <Grid item>
          <FormControl variant="outlined" className={classes.formControl}>
            <Button variant="contained"
                    color="primary"
                    size="large"
                    style={{minWidth: '200px', minHeight: '48px'}}
                    onClick={handleCreatePinShow}>
                Create Booking
            </Button>
          </FormControl>
        </Grid>
      </Grid>

      <CreatePinOptions
          role={role}
          allowGroupPinOption={allowGroupPinOption}
          show={showCreatePin}
          handleClose={handleCreatePinClose}
      />
      <AvailablePins
          show={showAvailablePins}
          handleClose={handleAvailablePinsClose}
      />
      <AvailableStaticPins
          show={showAvailableStaticPins}
          handleClose={handleAvailableStaticPinsClose}
      />

      <FindPinOptions
          show={showFindPins}
          handleClose={handleFindPinsClose}
      />

      <hr
        style={{
          border: "1px solid #041A27",
          opacity: 0.3
        }}
      />
      <div className="legend">
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography>
              <img src={AvailableIcon} alt="available" /> - Available
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <img src={InrangeInUseIcon} alt="inrange in use" /> - Inrange In Use
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <img src={InrangePlusInUseIcon} alt="inrange+ in use" /> - Inrange+ In Use
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <img src={AppInUseIcon} alt="app in use" /> - Inrange App In Use
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <img src={NotAvailableIcon} alt="not available" /> - Not Available
            </Typography>
          </Grid>
        </Grid>
      </div>
      {loading && (
        <div className="loading">
          <Typography color="primary">Loading Bays...</Typography>
          <CircularProgress color="primary" />
        </div>
      )}
      {!loading && (
        <div>
          {showOverview && <BaysStatusView />}
          {showLayout && <BayLayoutView />}
        </div>
      )}
    </MuiThemeProvider>
  );
};

export default Bays;
